import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import CompanyLogo from "./LogoW.png";

// const pages = [
//   { title: "会社情報", path: "/company" },
//   { title: "プレスリリース", path: "/pressrelease" },
//   // { title: "イベント紹介", path: "/events" },
// ];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* 画面が大きい時のロゴ設定 */}
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img src={CompanyLogo} alt="大画面ロゴ" height="40" width="150" />
          </Typography>

          {/* 画面が小さい時のメニュー */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="Company Info"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {
                //   pages.map((page) => (
                //   <MenuItem key={page.title} component="a" href={page.path}>
                //     <Typography textAlign="center">{page.title}</Typography>
                //   </MenuItem>
                // ))
              }
            </Menu>
          </Box>

          {/* 画面が小さい時のロゴ */}
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img src={CompanyLogo} alt="小画面ロゴ" height="40" width="150" />
          </Typography>

          {/* 画面が大きい時のメニュー */}
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {
              // pages.map((page) => (
              // <Button
              //   key={page.title}
              //   href={page.path}
              //   sx={{ my: 2, color: "white", display: "block" }}
              // >
              //   {page.title}
              // </Button>
              // ))
            }
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
