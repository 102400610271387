import Grid from "@mui/material/Grid";
import List from "./UserList.json";

type UserList = {
  name: string;
  avatar: string;
  charge: string;
  values: string;
  hobby: string;
};
const lists: UserList[] = List.user;

const Member = () => {
  return (
    <div style={{ marginTop: "5vh" }}>
      <div style={{ width: "max-content", backgroundColor: "#d7dbde" }}>
        <div
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            color: "#ffffff",
            marginBottom: "0px",
            marginTop: "30px",
            backgroundColor: "#1976d280",
            textShadow: "#0000ff 1px 0 1px",
            padding: "0.5rem 1rem 0 1rem",
            borderRadius: "10px 10px 0 0",
          }}
        >
          メンバー紹介
        </div>
      </div>
      <div>
        <Grid
          container
          sx={{ mt: 0, px: 2, pb: 2, backgroundColor: "#d7dbde" }}
        >
          {lists.map((item, index) => (
            <Grid item xs={12} md={4} key={item.name}>
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ verticalAlign: "top" }}>
                        <img
                          style={{
                            width: "50px" /*任意のサイズ*/,
                            height: "50px" /*任意のサイズ*/,
                            borderRadius: "50% 50%",
                            border: "solid 1px skyblue",
                            marginLeft: "5px",
                            display: "flex",
                          }}
                          src={item.avatar}
                          alt=""
                        ></img>
                      </td>
                      <td style={{ width: "90%" }}>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            position: "relative",
                            padding: "17px 13px 15px 18px",
                            height: "30vh",
                            backgroundColor: "white",
                            borderRadius: "20%",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            width: "90%",
                          }}
                        >
                          名前：{item.name} <br></br>
                          担当：{item.charge}
                          <br></br>
                          一言：{item.values}
                          <br></br>
                          趣味：{item.hobby}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default Member;
