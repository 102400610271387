import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import List from "./PagesList.json";
import { FadeInView } from "../../../helper/FadeInView";

type PagesList = {
  date: string;
  title: string;
  img: string;
  path: string;
  type: string;
  shortDescription: string;
};
type NewsList = {
  date: string;
  title: string;
  img: string;
  path: string;
  type: string;
  shortDescription: string;
};
const allLists: PagesList[] = List.pages;
const newsList: NewsList[] = allLists.filter((v) => v.type === "news");

const Contents1 = () => {
  return (
    <div>
      <div>
        <Grid
          container
          sx={{ mt: 0, px: 2, pb: 2, backgroundColor: "#ffffff" }}
          style={{ padding: "10px" }}
        >
          <Grid item xs={12} md={12} key={"top"} style={{ padding: "10px" }}>
            <div style={{ textAlign: "center" }}>
              <div style={{ fontSize: "17px", color: "#888888" }}>ニュース</div>
              <div style={{ fontSize: "10px", color: "#888888" }}>- news -</div>
            </div>
          </Grid>
          {newsList.map((item, index) => (
            <Grid
              item
              xs={6}
              md={6}
              key={item.title}
              style={{ padding: "10px" }}
            >
              <FadeInView>
                <Card>
                  <CardActionArea href={item.path}>
                    <CardMedia
                      component="img"
                      image={item.img}
                      alt="pic"
                      style={{ height: "7vh", objectFit: "cover" }}
                    />
                    <CardContent style={{ height: "110px" }}>
                      <Typography
                        gutterBottom
                        style={{ fontSize: " 12px" }}
                        component="div"
                      >
                        {item.date}
                        <br />
                        {item.title}
                        <br />
                        <br />
                      </Typography>
                      <Typography
                        gutterBottom
                        style={{ fontSize: " 14px" }}
                        component="div"
                      >
                        {item.shortDescription}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </FadeInView>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};
export default Contents1;
