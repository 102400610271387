import styles from "./styles.module.css";
import { Typography, Link } from "@mui/material";

const ResponsiveFooter = () => {
  return (
    <div className={styles.footer} style={{ height: "20vh" }}>
      <Typography variant="body2" color="white" align="center">
        {"Copyright © "}
        <Link color="inherit" href="https://lamact.com/">
          lamact.com
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
        <br />
        <Link color="inherit" href="https://line.me/R/ti/p/@729gokgs">
          お問い合わせ
        </Link>
      </Typography>
    </div>
  );
};
export default ResponsiveFooter;
