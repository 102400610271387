import { Typography } from "@mui/material";
import LineQR from "./LineQR.png";

const LINE = () => {
  const styles = {
    container: {
      padding: "1rem",
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
    },
    content: {
      padding: "1rem",
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
    },
  };

  return (
    <div>
      <div style={styles.container}>
        <img src={LineQR} style={{ width: "35vw", objectFit: "cover" }} />
      </div>
      <strong style={styles.content}>
        QRコードをスキャンするとLINEの友だちに追加されます<br></br>
        LINEアプリの友だちタブを開き、画面右上にある友だち追加ボタン＞<br></br>
        [QRコード]をタップして、コードリーダーでスキャンしてください。
      </strong>
    </div>
  );
};

export default LINE;
