import React, { useState, useEffect } from "react";
import { useTransition, animated } from "@react-spring/web";
import styles from "../../styles.module.css";
import List from "./RecomendList.json";

type ShowList = {
  title: string;
  img: string;
  path: string;
};
const lists: ShowList[] = List.pages;
const slides = lists.map((v) => v.img);

const RecommendService = () => {
  const [index, setIndex] = useState(0);
  const transitions = useTransition(index, {
    key: index,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 3000 },
    onRest: (_a: any, _b: any, item: number) => {
      if (index === item) {
        setIndex((state) => (state + 1) % slides.length);
      }
    },
    exitBeforeEnter: true,
  });
  return (
    <div className="flex center">
      {transitions((style: any, i: number) => (
        <animated.div
          className={styles.bg}
          style={{
            ...style,
            backgroundImage: `url(${slides[i]})`,
            // backgroundSize: "80%",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            height: "30vh",
          }}
        />
      ))}
    </div>
  );
};
export default RecommendService;
