import { useRef, useLayoutEffect } from "react";
import testimage from "../image/testimage.png";
import { Link, Button } from "@mui/material";

const Action = () => {
  const styles = {
    container: {
      // display: "flex",
      flexDirection: "column" as "column",
      justifyContent: "center",
      alignItems: "center",

      backgroundColor: "#ffffff",
    },
    title: {
      fontSize: "2.5rem",
      color: "#2c3e50",
      marginBottom: "1rem",
    },
    text: {
      fontSize: "1.5rem",
      color: "#34495e",
    },
  };

  const scrollBottomRef1 = useRef<HTMLDivElement>(null);
  const scrollBottomRef2 = useRef<HTMLDivElement>(null);
  const scrollBottomRef3 = useRef<HTMLDivElement>(null);

  const scrollToSection1 = () => {
    scrollBottomRef1?.current?.scrollIntoView();
  };

  const scrollToSection2 = () => {
    scrollBottomRef2?.current?.scrollIntoView();
  };

  const scrollToSection3 = () => {
    scrollBottomRef3?.current?.scrollIntoView();
  };

  const items: string[] = [];

  return (
    <div>
      <div style={styles.container}>
        <h1 style={styles.title}>活動内容</h1>
        <h2 style={styles.text}>資材へのリンク集となります</h2>

        <Button
          onClick={() => {
            scrollToSection1();
          }}
        >
          GitHub
        </Button>
        <Button
          onClick={() => {
            scrollToSection2();
          }}
          href="#text-buttons"
        >
          GoogleDrive
        </Button>
        <Button
          onClick={() => {
            scrollToSection3();
          }}
          href="#text-buttons"
        >
          Trello
        </Button>
      </div>
      <section id="section1">
        <div ref={scrollBottomRef1} />
        <div style={styles.container}>
          <h3 style={styles.title}>GitHub</h3>

          <p style={styles.text}>
            Lamactは成果物の変更管理にgithubを利用しております
            <br />
            メンバ参画の際、githubへのアクセス権を付与します
            <br />
            <Link href="https://github.com/LamactPrivate" color="#6495ed">
              プライベートリポジトリはこちら
            </Link>
          </p>
          {/* <img src={testimage} alt="テスト" height="40" width="120"></img> */}
        </div>
      </section>
      <section id="section2">
        <div ref={scrollBottomRef2} />
        <div style={styles.container}>
          <h3 style={styles.title}>GoogleDrive</h3>
          <p style={styles.text}>
            Lamactは調整資料や中間成果物の管理にgoogleDriveを利用しております
            <br />
            メンバ参画の際、該当ファイルへのアクセス権を付与します
            <br />
            <Link
              href="https://drive.google.com/drive/folders/1toN1sAcCSUn6rsSVu-kr4VzH19ruc_D9"
              color="#6495ed"
            >
              googleDriveはこちら
            </Link>
          </p>
          {/* <img src={testimage} alt="テスト" height="40" width="120"></img> */}
        </div>
      </section>
      <section id="section3">
        <div ref={scrollBottomRef3} />
        <div style={styles.container}>
          <h3 style={styles.title}>Trello</h3>
          <p style={styles.text}>
            Lamactはタスク管理にTrelloを利用しております
            <br />
            メンバ参画の際、ボードへのアクセス権を付与します
            <br />
            <Link
              href="https://trello.com/w/usercba70fbd1441592496a21ae2b1494178"
              color="#6495ed"
            >
              Trelloはこちら
            </Link>
          </p>
          {/* <img src={testimage} alt="テスト" height="40" width="120"></img> */}
        </div>
      </section>
    </div>
  );
};

export default Action;
