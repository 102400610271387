import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import List from "./PagesList.json";
import { FadeInView } from "../../../helper/FadeInView";

import Lottie from "react-lottie";
import * as animationData from "./mail2.json";

type PagesList = {
  title: string;
  img: string;
  path: string;
  type: string;
  shortDescription: string;
};
type CommunicationList = {
  title: string;
  img: string;
  path: string;
  type: string;
  shortDescription: string;
};
const allLists: PagesList[] = List.pages;
const communicationList: CommunicationList[] = allLists.filter(
  (v) => v.type === "communication"
);

const Contents2 = () => {
  return (
    <div>
      <div>
        <Grid
          container
          sx={{ mt: 0, px: 2, pb: 2, backgroundColor: "#f0f0f0" }}
          style={{ padding: "10px" }}
        >
          <Grid item xs={6} md={6} key={"test"} style={{ padding: "10px" }}>
            {communicationList.map((item, index) => (
              <div>
                <Grid item xs={12} md={12} key={item.title}>
                  <FadeInView>
                    <Card>
                      <CardActionArea href={item.path}>
                        <CardMedia
                          component="img"
                          image={item.img}
                          alt="pic"
                          style={{ objectFit: "contain", height: "70px" }}
                        />
                      </CardActionArea>
                    </Card>
                  </FadeInView>
                </Grid>
                <br />
              </div>
            ))}
          </Grid>
          <Grid item xs={6} md={6} key={"top"} style={{ padding: "10px" }}>
            <FadeInView>
              <div
                style={{
                  textAlign: "center",
                  //alignItems: "center",
                  //position: "absolute",
                  //justifyContent: "center",
                  //height: "30vh",
                }}
              >
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: animationData,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                  height={113}
                  width={113}
                />
                <div style={{ textAlign: "center" }}>
                  <div style={{ fontSize: "17px", color: "#888888" }}>
                    お問い合わせ
                  </div>
                  <div style={{ fontSize: "10px", color: "#888888" }}>
                    - inquiry -
                  </div>
                </div>
              </div>
            </FadeInView>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default Contents2;
